import Vue from 'vue';
import VueBootstrap from 'bootstrap-vue';
import VueSuperagent from 'vue-superagent';
import VueHighlightJS from 'vue-highlightjs';
import VueVirtualScroller from 'vue-virtual-scroller';
import vSelect from 'vue-select';
import Toast, { POSITION } from 'vue-toastification';
import DatePicker from 'vue2-datepicker';
import VueApexCharts from 'vue-apexcharts';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'highlight.js/scss/googlecode.scss';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import 'vue-select/dist/vue-select.css';
import 'vue-toastification/dist/index.css';
import 'vue2-datepicker/index.css';
import './css/coloradmin/base.scss';
import './css/custom/base.scss';
import App from './App.vue';
import router from './router';
import store from './store';
import mixins from './mixins';
import filters from './filters';

Vue.use(VueBootstrap);
Vue.use(VueSuperagent, {
  baseUrl: '/api',
});
Vue.use(VueHighlightJS);
Vue.use(VueVirtualScroller);
Vue.component('v-select', vSelect);
Vue.component('apexchart', VueApexCharts);
Vue.use(Toast, {
  timeout: 3000,
  transition: 'fade',
  position: POSITION.BOTTOM_RIGHT,
});
Vue.use(DatePicker);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  mixins,
  filters,
  render: (h) => h(App),
}).$mount('#app');
