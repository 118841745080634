import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "SignIn" */ '@/views/SignIn.vue'),
  },
  {
    path: '/signout',
    name: 'SignOut',
    component: () => import(/* webpackChunkName: "SignOut" */ '@/views/SignOut.vue'),
  },
  {
    path: '/design',
    name: 'Design',
    component: () => import(/* webpackChunkName: "Design" */ '@/views/Design.vue'),
  },
  {
    path: '/designs',
    name: 'Designs',
    component: () => import(/* webpackChunkName: "Designs" */ '@/views/Designs.vue'),
  },
  {
    path: '/colors',
    name: 'Colors',
    component: () => import(/* webpackChunkName: "Colors" */ '@/views/Colors.vue'),
  },
  {
    path: '/pantone',
    name: 'Pantone',
    component: () => import(/* webpackChunkName: "Pantone" */ '@/views/Pantone.vue'),
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import(/* webpackChunkName: "Invoices" */ '@/views/Invoices.vue'),
  },
  {
    path: '/files',
    name: 'Files',
    component: () => import(/* webpackChunkName: "Files" */ '@/views/Files.vue'),
  },
  {
    path: '/notes',
    name: 'Notes',
    component: () => import(/* webpackChunkName: "Notes" */ '@/views/Notes.vue'),
  },
  {
    path: '/burn_test',
    name: 'BurnSamples',
    component: () => import(/* webpackChunkName: "BurnSamples" */ '@/views/BurnSamples.vue'),
  },
  {
    path: '/lifetime_test',
    name: 'LifetimeSamples',
    component: () => import(/* webpackChunkName: "LifetimeSamples" */ '@/views/LifetimeSamples.vue'),
  },
  {
    path: '/soot_test',
    name: 'SootSamples',
    component: () => import(/* webpackChunkName: "SootSamples" */ '@/views/SootSamples.vue'),
  },
  {
    path: '/tint_history',
    name: 'TintHistory',
    component: () => import(/* webpackChunkName: "TintHistory" */ '@/views/TintHistory.vue'),
  },
  {
    path: '/developer',
    name: 'Developer',
    component: () => import(/* webpackChunkName: "Developer" */ '@/views/Developer.vue'),
  },
  {
    path: '/oilwax_calculator',
    name: 'OilWaxCalculator',
    component: () => import(/* webpackChunkName: "OilWaxCalculator" */ '@/views/OilWaxCalculator.vue'),
  },
  {
    path: '/production_cost',
    name: 'ProductionCost',
    component: () => import(/* webpackChunkName: "ProductionCost" */ '@/views/ProductionCost.vue'),
  },
  {
    path: '/wick_configurator',
    name: 'WickConfigurator',
    component: () => import(/* webpackChunkName: "WickConfigurator" */ '@/views/WickConfigurator.vue'),
  },
  {
    path: '/wick_test',
    name: 'WickSamples',
    component: () => import(/* webpackChunkName: "WickSamples" */ '@/views/WickSamples.vue'),
  },
  {
    path: '/burn_testing',
    name: 'BurnTesting',
    component: () => import(/* webpackChunkName: "BurnTesting" */ '@/views/BurnTesting.vue'),
  },
  {
    path: '/labels',
    name: 'Labels',
    component: () => import(/* webpackChunkName: "Labels" */ '@/views/Labels.vue'),
  },
  {
    path: '/label_raw',
    name: 'LabelRaw',
    component: () => import(/* webpackChunkName: "LabelRaw" */ '@/views/LabelRaw.vue'),
  },
  {
    path: '/pcn_shortcuts',
    name: 'PcnShortcuts',
    component: () => import(/* webpackChunkName: "PcnShortcuts" */ '@/views/PcnShortcuts.vue'),
  },
  {
    path: '/picture_upload',
    name: 'PictureUpload',
    component: () => import(/* webpackChunkName: "PictureUpload" */ '@/views/PictureUpload.vue'),
  },
  { path: '*', component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue') },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
